import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import { getJwtToken, onAuthChange } from "~/util/auth";

// Vue.use(VueApollo);

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: "//" + location.host + "/graphql",
  credentials: "same-origin",
});

let operationsWaitingForAuth = [];
let hasAuth = false;

onAuthChange(function (auth) {
  console.log("onAuthChange", auth);
  if (auth && auth.inited && auth.authToken) {
    hasAuth = true;
    if (operationsWaitingForAuth && operationsWaitingForAuth.length > 0) {
      const operations = [...operationsWaitingForAuth];
      operationsWaitingForAuth = [];
      console.log("Now have auth, waiting operations: ", operations);
      for (let i = 0; i < operations.length; i++) {
        const [operation, forward] = operations[i];
        console.log("now execute ", operation, forward(operation));
      }
    }
  } else {
    hasAuth = false;
  }
});
const waitForAuthLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });
  const ctx = operation.getContext();
  if (ctx.needsAuth && !hasAuth) {
    console.log("queue GraphQL operation because it needs auth", operation);
    operationsWaitingForAuth.push([operation, forward]);
  } else {
    return forward(operation);
  }
});

const authLink = setContext((_, { headers }) => {
  const csrfToken = (document.querySelector("meta[name=csrf-token]") || {})
    .content;
  const jwtToken = getJwtToken();
  return {
    headers: {
      ...headers,
      "X-CSRF-Token": csrfToken,
      "X-Env": location.host,
      authorization: jwtToken ? `Bearer ${jwtToken}` : "",
    },
  };
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: waitForAuthLink.concat(authLink.concat(httpLink)),
  cache,
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export function clearGraphQLCache() {
  console.log("clearGraphQLCache", cache);
  return cache.reset();
}

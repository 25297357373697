<template>
  <div :class="[$style.panel, $slots.title && main ? $style.mainPanel : null]">
    <template v-if="$slots.title && main">
      <img
        :class="$style.triangle"
        alt=""
        height="12"
        src="~assets/triangle.svg"
        width="12"
      />
      <slot name="title" />
    </template>
    <slot v-else name="title" />
    <slot />
  </div>
</template>

<script>
export default {
  name: "AppPanel",
  props: {
    main: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="module.scss" module>
.panel {
  box-shadow: 1px 1px 2px rgba(#333, 0.15);
  background: rgba(#999, 0.1);
  padding: 10px;
}
.panel + .panel {
  margin-top: 2rem;
}

.triangle {
  float: right;
}

.mainPanel > :global(.AppTitle) {
  text-align: right;
  margin-right: 14px;
}
</style>

<template>
  <span
    :class="[
      $style.wrap,
      {
        [$style.center]: center,
        [$style.right]: right,
        [$style.stacked]: stacked,
      },
    ]"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "ActionList",
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module lang="module.css">
.wrap {
  display: flex;
  align-items: center;
}
.wrap.center {
  justify-content: center;
}
.wrap.right {
  justify-content: flex-end;
}
.wrap.stacked {
  flex-direction: column;
}
.wrap > em {
  color: #999;
  margin-left: 2rem;
  margin-right: 2rem;
}
.wrap > em:first-child {
  margin-left: 0;
}
.wrap > em:last-child {
  margin-right: 0;
}
.wrap.stacked > em {
  color: #999;
  margin: 0.7rem 0;
}
</style>

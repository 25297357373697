<template>
  <div :class="$style.wrap">
    <span :class="$style.line" v-if="$route.name !== 'login'"
      >Schon registriert?
      <router-link :to="{ name: 'login' }">Einloggen</router-link></span
    >
    <span :class="$style.line" v-if="$route.name !== 'register'"
      >Noch kein Benutzerkonto?
      <router-link :to="{ name: 'register' }">Registrieren</router-link></span
    >
    <span :class="$style.line" v-if="$route.name !== 'password-reset'"
      >Passwort vergessen?
      <router-link :to="{ name: 'password-reset' }"
        >Passwort zurücksetzen</router-link
      ></span
    >
    <span :class="$style.line" v-if="$route.name !== 'validate-email'"
      >E-Mail Bestätigung nicht bekommen?
      <router-link :to="{ name: 'validate-email' }"
        >E-Mail Bestätigung wiederholen</router-link
      ></span
    >
    <span :class="$style.line" v-if="$route.name !== 'unlock-email'"
      >Konto gesperrt?
      <router-link :to="{ name: 'unlock-email' }"
        >Konto entsperren</router-link
      ></span
    >
  </div>
</template>

<script>
export default {
  name: "AuthMetaNavi",
};
</script>

<style lang="module.scss" module>
.wrap {
  display: block;
  font-size: 80%;
  line-height: 1.4;
  margin-top: 10px;
}

.line {
  display: block;
}
</style>

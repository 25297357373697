<template>
  <app-panel>
    <div v-if="$slots.filter" :class="$style.filter">
      <slot name="filter" />
    </div>
    <el-table
      ref="table"
      :data="list"
      row-key="id"
      size="medium"
      stripe
      :class="$style.table"
      @expand-change="onExpandedChange"
      @row-click="onRowClick"
    >
      <el-table-column type="expand">
        <template #default="{ row }">
          <message-entry :entry="row" />
        </template>
      </el-table-column>
      <el-table-column prop="messageType" width="24px" v-if="!$root.isMobile">
        <template #default="{ row }">
          <i
            v-if="row.messageType === 'EVIDENCE_ACCEPTED'"
            class="el-icon-success u-success-text"
          />
          <i
            v-else-if="row.messageType === 'EVIDENCE_DECLINED'"
            class="el-icon-warning u-danger-text"
          />
          <i
            v-else-if="row.messageType === 'SUBSCRIPTION_ACCEPTED'"
            class="el-icon-warning u-success-text"
          />
          <i
            v-else-if="row.messageType === 'SUBSCRIPTION_REJECTED'"
            class="el-icon-warning u-danger-text"
          />
          <i v-else class="el-icon-info" />
        </template>
      </el-table-column>
      <el-table-column label="Nachricht" prop="title">
        <template #default="{ row }">
          <el-tag
            v-if="$root.isMobile && !row.read"
            effect="dark"
            size="mini"
            type="primary"
            :class="$style.inlineNew"
          >
            <i class="el-icon-message-solid" /> neu
          </el-tag>
          {{ row.title }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="!$root.isMobile"
        align="right"
        prop="read"
        width="72px"
      >
        <template #default="{ row }">
          <el-tag v-if="!row.read" effect="dark" size="mini" type="primary">
            <i class="el-icon-message-solid" /> neu
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!$root.isMobile"
        label="Zeitpunkt"
        prop="createdAt"
        width="190px"
      >
        <template #default="{ row }">
          <locale-date-time :date="row.createdAt" />
        </template>
      </el-table-column>
      <p slot="empty" :class="$style.empty">
        <slot name="empty"> <i class="el-icon-bell" /> Keine Nachrichten </slot>
      </p>
    </el-table>
    <data-table-footer
      :has-next-page="hasNextPage"
      :has-previous-page="hasPreviousPage"
      :reload="reload"
      :table="$refs.table"
      @next-page="goToNextPage"
      @previous-page="goToPreviousPage"
    >
      <template slot="actions">
        <slot name="actions" />
      </template>
    </data-table-footer>
  </app-panel>
</template>

<script>
import MessageEntry from "~/components/messages/MessageEntry";
import AppPanel from "~/components/common/layout/AppPanel";
import DataTableFooter from "~/components/common/list/DataTableFooter";
import DataTableParent from "~/components/mixins/DataTableParent";
import { emptyFn } from "~/util/empty-fn";
import LocaleDateTime from "~/components/common/i18n/LocaleDateTime";

export default {
  name: "MessageList",
  components: { LocaleDateTime, DataTableFooter, AppPanel, MessageEntry },
  mixins: [DataTableParent],
  props: {
    onMessageExpanded: {
      type: Function,
      default: emptyFn(),
    },
  },
  methods: {
    onExpandedChange(row, expandedRows) {
      if (expandedRows.indexOf(row) > -1) {
        this.onMessageExpanded(row);
      }
    },
  },
};
</script>

<style lang="module.scss" module>
.empty {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.filter {
  composes: filterWrap from '~assets/base.module.css';
}

.inlineNew {
  float: right;
  margin-left: 0.5rem;
}

@media all and (max-width: 599px) {
  .table :global(.el-table__expanded-cell[class*=cell]) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

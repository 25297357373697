<script>
const PROBABLY_PHONE =
  /iphone|android|ie|blackberry|fennec/.test(
    navigator.userAgent.toLowerCase()
  ) && "ontouchstart" in document.documentElement;

export default {
  name: "PhoneNumber",
  functional: true,
  props: {
    phone: {
      type: String,
      default: "",
    },
  },
  render(createElement, context) {
    console.log("context.children", context.children);
    if (PROBABLY_PHONE) {
      const { phone } = context.props;
      return createElement(
        "span",
        {
          class: "u-no-break",
        },
        [
          createElement(
            "a",
            {
              attrs: {
                href: `tel:${phone}`,
                title: "Telefon",
              },
            },
            [createElement("em", { class: "el-icon-phone" })]
          ),
          " ",
          createElement(
            "a",
            {
              attrs: {
                href: `tel:${phone}`,
              },
            },
            context.children
          ),
        ]
      );
    }
    return createElement("span", [
      createElement("em", { class: "el-icon-phone", title: "Telefon" }),
      ...context.children,
    ]);
  },
};
</script>

import IsMobile from "~/components/mixins/IsMobile";

export default {
  name: "RootMixin",
  mixins: [IsMobile],
  computed: {
    loggedIn() {
      return this.$auth.loggedIn;
    },
    meId() {
      return this.$auth.userId || null;
    },
  },
  methods: {
    refreshBadges() {
      if (this.$children.length > 0) {
        this.$children[0].refreshBadges();
      }
    },
    jumpTo(route) {
      if (this.altPressed) {
        const resolvedRoute = this.$router.resolve(route);
        window.open(resolvedRoute.href, "_blank", "noopener");
      } else {
        this.$router.push(route);
      }
    },
  },
};

<template>
  <div>
    <div v-if="$root.isMobile" :class="$style.meta">
      <locale-date-time :date="entry.createdAt" />
    </div>
    <div :class="$style.message" v-html="messageAsHtml" />
  </div>
</template>

<script>
import LocaleDateTime from "~/components/common/i18n/LocaleDateTime";
export default {
  name: "MessageEntry",
  components: { LocaleDateTime },
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    messageAsHtml() {
      const { message } = this.entry;
      return message;
    },
  },
};
</script>

<style lang="module.scss" module>
.meta {
  text-align: right;
  font-style: italic;
  margin-bottom: 0.5rem;
}
.message p, .message li {
  margin-bottom: 0.75rem;
}
.message.message :global(table.property-table) {
  border: 1px solid #333;
  border-collapse: collapse;
  margin: 1rem 0;
}
.message.message :global(table.weekdays) {
  border-width: 0;
  border-collapse: collapse;
  margin: 0;
}
.message.message :global(table.property-table) > tbody > tr > th, .message.message :global(table.property-table) > tbody > tr > td {
  text-align: left;
  vertical-align: top;
  border: 1px solid #333;
  padding: 4px;
}
.message.message :global(table.weekdays) > tbody > tr > th, .message.message :global(table.weekdays) > tbody > tr > td {
  border-width: 0;
  border-collapse: collapse;
  padding: 0;
}

.message.message :global(.terms) {
  font-size: 80%;
  padding: 10px;
  background: #cccccc;
  border-radius: 6px;
}
.message.message :global(.terms) p, .message.message :global(.terms) li {
  margin: 0 0 0.25rem;
}
.message.message :global(.terms) ul {
  margin: 0 0 0.25rem;
  padding: 0 0 0 1.25rem;
}
.message.message :global(.terms) ol {
  margin: 0 0 0.25rem;
  padding: 0 0 0 1rem;
}

@media all and (max-width: 599px) {
  .message :global(table.property-table) {
    border-width: 0 !important;
  }
  .message :global(table.property-table) > tbody > tr {
    display: block !important;
    margin: 0 0 1em !important;
  }
  .message :global(table.property-table) > tbody > tr > th {
    border-width: 0 0 1px 0 !important;
    display: block !important;
  }
  .message :global(table.property-table) > tbody > tr > td {
    border-width: 0 !important;
    display: block !important;
  }
}
</style>

<template>
  <el-dialog
    v-loading="loading"
    width="300px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    :visible.sync="vis"
    title="Erneut einloggen"
    center
  >
    <el-alert
      :closable="false"
      :type="alertType"
      show-icon
      :class="$style.alert"
      :description="alertText"
    />
    <el-form
      ref="form"
      label-position="top"
      :model="model"
      :rules="{
        password: { required: true, message: 'Notwendig', trigger: 'blur' },
      }"
      @submit.native.prevent
    >
      <el-form-item label="Passwort" prop="password">
        <el-input
          ref="input"
          v-model="model.password"
          type="password"
          @keypress.native.enter="submit().catch(() => {})"
        />
      </el-form-item>
    </el-form>
    <action-list slot="footer" right>
      <el-button type="primary" @click="submit().catch(() => {})">
        Login
      </el-button>
    </action-list>
  </el-dialog>
</template>

<script>
import Overlay from "~/components/mixins/Overlay";
import ActionList from "~/components/common/utils/ActionList";
import { ensureValidForm } from "~/util/validation";

export default {
  name: "ReLoginOverlay",
  components: { ActionList },
  mixins: [Overlay],
  props: {
    email: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    doAuthenticate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: 0,
      model: {
        password: "",
      },
    };
  },
  computed: {
    alertType() {
      const { error } = this;
      return error ? "error" : "warning";
    },
    alertText() {
      const { error } = this;
      return (
        error || "Sie müssen sich erneut einloggen, um fortfahren zu können"
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.$el.focus();
    });
  },
  methods: {
    submit() {
      return ensureValidForm(this.$refs.form, () => {
        this.loading += 1;
        return this.doAuthenticate({
          email: this.email,
          password: this.model.password,
        })
          .finally(() => {
            this.loading -= 1;
          })
          .catch(() => {
            this.$refs.form.resetFields();
            this.$nextTick(() => {
              this.$refs.form.clearValidate();
            });
          });
      });
    },
  },
};
</script>

<style module lang="module.css">
.alert.alert {
  margin-bottom: 1rem;
}
</style>

import gql from "graphql-tag";

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;

export const ErrorFragment = gql`
  fragment ErrorFragment on Error {
    key
    messages
    details {
      error
      value
      count
    }
  }
`;

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    id
    messageType
    title
    message
    read
    createdAt
  }
`;

export const CommentFragment = gql`
  fragment CommentFragment on Comment {
    id
    recordId
    recordType
    createdAt
    updatedAt
    userId
    body
    user {
      name
      email
    }
  }
`;

export const PeakIntoCommentsFragment = gql`
  fragment PeakIntoCommentsFragment on CommentConnection {
    nodes {
      ...CommentFragment
    }
    totalCount
  }
  ${CommentFragment}
`;

export const BankAccountFragment = gql`
  fragment BankAccountFragment on BankAccount {
    id
    iban
    mandateReference
    status
    startUsingAt
    signDate
    updatedAt
    accountHolderName
    accountHolderStreet
    accountHolderLocality
    accountHolderZip
    accountHolderCountryCode
  }
`;

export const BackendBankAccountFragment = gql`
  fragment BackendBankAccountFragment on BankAccount {
    id
    iban
    mandateReference
    status
    startUsingAt
    signDate
    updatedAt
    accountHolderName
    accountHolderStreet
    accountHolderLocality
    accountHolderZip
    accountHolderCountryCode
    installments {
      totalCount
    }
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
  }
  ${PeakIntoCommentsFragment}
`;

export const InstallmentFragment = gql`
  fragment InstallmentFragment on Installment {
    id
    amount
    originalAmount
    dueDate
    month
    note
    booked
  }
`;

export const EvidenceFragment = gql`
  fragment EvidenceFragment on Evidence {
    id
    for
    status
    createdAt
    declineNote
    uploads {
      key
      name
      previewUrl
      lightboxUrl
      downloadUrl
    }
  }
`;

export const EvidenceExtraFragment = gql`
  fragment EvidenceExtraFragment on Evidence {
    updatedAt
    child {
      id
      firstName
      lastName
      birthday
    }
    user {
      id
      fullSalutation
    }
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
  }
  ${PeakIntoCommentsFragment}
`;

export const ChildFragment = gql`
  fragment ChildFragment on Child {
    id
    firstName
    lastName
    birthday
    deletable
    measlesProtectionStatus: allEvidences(for: MEASLES_PROTECTION) {
      ...EvidenceFragment
    }
  }
  ${EvidenceFragment}
`;

export const BackendChildFragment = gql`
  fragment BackendChildFragment on Child {
    id
    firstName
    lastName
    birthday
    deletable
    measlesProtectionStatus: allEvidences(for: MEASLES_PROTECTION) {
      ...EvidenceFragment
    }
    subscriptions {
      totalCount
    }
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
  }
  ${EvidenceFragment}
  ${PeakIntoCommentsFragment}
`;

export const ServiceFragment = gql`
  fragment ServiceFragment on Service {
    id
    contractType
    name
    stjaEntity
    basicFee
    possibleSubscriptionStarts
    possibleSubscriptionEnds
    terms
    moreInfoUrl
    logoUrl
    dayConfigurationPossible
    numValidWeekdays
    weekdays {
      day
      fee
      startTime
      endTime
    }
  }
`;
export const BackendServiceFragment = gql`
  fragment BackendServiceFragment on Service {
    id
    contractType
    name
    createdAt
    updatedAt
    stjaEntity
    basicFee
    wholeWeekDiscount
    possibleSubscriptionStarts
    possibleSubscriptionEnds
    terms
    moreInfoUrl
    logoUrl
    counterAccount
    costCenter
    costObject
    contractType
    dayConfigurationPossible
    visibleToSubscriber
    numValidWeekdays
    weekdays {
      day
      fee
      startTime
      endTime
    }
    school {
      id
      name
    }
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
  }
  ${PeakIntoCommentsFragment}
`;

export const SubscriptionFragment = gql`
  fragment SubscriptionFragment on Subscription {
    service {
      id
      contractType
      needsMeaslesProtection
      name
      moreInfoUrl
      logoUrl
      weekdays {
        day
        startTime
        endTime
        fee
      }
      numValidWeekdays
      school {
        id
        name
      }
      dayConfigurationPossible
    }
    child {
      ...ChildFragment
    }
    emergencyContacts {
      id
      who
      phone
      note
    }
    plannedConfigurationChange {
      weekdays
    }
    id
    identifier
    status
    cancelable
    uncancelable
    deletable
    editable
    extendable
    configurationEditable
    changesTakeEffectOn
    startsOn
    renewsOn
    endsOn
    plannedConfigurationChangeOn
    termsAcceptedAt
    monthlyTotal
    plannedConfigurationMonthlyTotal
    selectedWeekdays
    notes
    whoCanConfigure
    user {
      id
    }
  }
  ${ChildFragment}
`;

export const BackendSubscriptionExtraFragment = gql`
  fragment BackendSubscriptionExtraFragment on Subscription {
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
  }
  ${PeakIntoCommentsFragment}
`;

export const EmployeeFragment = gql`
  fragment EmployeeFragment on User {
    id
    salutation
    name
    email
    unconfirmedEmail
    status
    disabled
    role
    rights
    createdAt
    updatedAt
    serviceIds
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
  }
  ${PeakIntoCommentsFragment}
`;

export const SubscriberFragment = gql`
  fragment SubscriberFragment on User {
    id
    salutation
    name
    email
    unconfirmedEmail
    status
    disabled
    createdAt
    updatedAt
    comments(first: 1, newestFirst: true) {
      ...PeakIntoCommentsFragment
    }
    children {
      totalCount
    }
    bankAccounts {
      totalCount
    }
    subscriptions {
      totalCount
    }
  }
  ${PeakIntoCommentsFragment}
`;

import { emptyFn } from "~/util/empty-fn";
import { hasMouseIntractableParent } from "~/util/dom";

export default {
  name: "DataTableParent",
  props: {
    list: {
      type: Array,
      required: true,
    },
    loading: {
      type: [Number, Boolean],
      default: 0,
    },
    reload: {
      type: Function,
      default: emptyFn,
    },
    goToNextPage: {
      type: Function,
      default: emptyFn,
    },
    goToPreviousPage: {
      type: Function,
      default: emptyFn,
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    hasPreviousPage: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  methods: {
    onRowClick(row, column, event) {
      if (!hasMouseIntractableParent(event.target)) {
        this.$refs.table.toggleRowExpansion(row);
      }
    },
  },
};

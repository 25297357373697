<template>
  <el-main class="Content">
    <div :class="$style.container">
      <div :class="$style.logoWrap">
        <logo :class="$style.logo" :triangle-color="triangleColor" />
      </div>
      <div :class="$style.text">
        <slot />
      </div>
    </div>
  </el-main>
</template>

<script>
import Logo from "~/components/common/layout/Logo";
import { STJA_COLORS } from "~/util/color";

function getRandomColor() {
  const possibleColors = [
    "tuerkis",
    "signalgruen",
    "gelbgruen",
    "maingruen",
    "gelb",
    "orange",
    "signalrot",
    "kirschrot",
    "purpur",
    "flieder",
    "dunkelblau",
    "mittelblau",
  ];

  return STJA_COLORS[
    possibleColors[Math.floor(Math.random() * possibleColors.length)]
  ];
}

export default {
  name: "StaticPageChrome",
  components: { Logo },
  data() {
    return {
      triangleColor: getRandomColor(),
    };
  },
  mounted() {
    this._setTriangleColorInterval = setInterval(() => {
      this.triangleColor = getRandomColor();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this._setTriangleColorInterval);
  },
};
</script>

<style module lang="module.css">
.container {
  max-width: 750px;
  margin: auto;
}
.logoWrap {
  margin-top: 30px;
  text-align: right;
}
.logo {
  width: 50vw;
  min-width: 250px;
  max-width: 400px;
  height: auto;
}

.text {
  padding: 15px 10px 0;
}
.text h1 {
  font-size: 32px;
}
.text h2 {
  margin-top: 1rem;
}
.text h3 {
  margin-top: 1rem;
}
.text h4 {
  margin-top: 0.5rem;
}
.text p, .text ul, .text ol {
  margin-top: 0.5rem;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-panel',[(_vm.$slots.filter)?_c('div',{class:_vm.$style.filter},[_vm._t("filter")],2):_vm._e(),_vm._v(" "),_c('el-table',{ref:"table",class:_vm.$style.table,attrs:{"data":_vm.list,"row-key":"id","size":"medium","stripe":""},on:{"expand-change":_vm.onExpandedChange,"row-click":_vm.onRowClick}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('message-entry',{attrs:{"entry":row}})]}}])}),_vm._v(" "),(!_vm.$root.isMobile)?_c('el-table-column',{attrs:{"prop":"messageType","width":"24px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.messageType === 'EVIDENCE_ACCEPTED')?_c('i',{staticClass:"el-icon-success u-success-text"}):(row.messageType === 'EVIDENCE_DECLINED')?_c('i',{staticClass:"el-icon-warning u-danger-text"}):(row.messageType === 'SUBSCRIPTION_ACCEPTED')?_c('i',{staticClass:"el-icon-warning u-success-text"}):(row.messageType === 'SUBSCRIPTION_REJECTED')?_c('i',{staticClass:"el-icon-warning u-danger-text"}):_c('i',{staticClass:"el-icon-info"})]}}],null,false,3776566959)}):_vm._e(),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Nachricht","prop":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.$root.isMobile && !row.read)?_c('el-tag',{class:_vm.$style.inlineNew,attrs:{"effect":"dark","size":"mini","type":"primary"}},[_c('i',{staticClass:"el-icon-message-solid"}),_vm._v(" neu\n        ")]):_vm._e(),_vm._v("\n        "+_vm._s(row.title)+"\n      ")]}}])}),_vm._v(" "),(!_vm.$root.isMobile)?_c('el-table-column',{attrs:{"align":"right","prop":"read","width":"72px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.read)?_c('el-tag',{attrs:{"effect":"dark","size":"mini","type":"primary"}},[_c('i',{staticClass:"el-icon-message-solid"}),_vm._v(" neu\n        ")]):_vm._e()]}}],null,false,1243459553)}):_vm._e(),_vm._v(" "),(!_vm.$root.isMobile)?_c('el-table-column',{attrs:{"label":"Zeitpunkt","prop":"createdAt","width":"190px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('locale-date-time',{attrs:{"date":row.createdAt}})]}}],null,false,3360180963)}):_vm._e(),_vm._v(" "),_c('p',{class:_vm.$style.empty,attrs:{"slot":"empty"},slot:"empty"},[_vm._t("empty",function(){return [_c('i',{staticClass:"el-icon-bell"}),_vm._v(" Keine Nachrichten ")]})],2)],1),_vm._v(" "),_c('data-table-footer',{attrs:{"has-next-page":_vm.hasNextPage,"has-previous-page":_vm.hasPreviousPage,"reload":_vm.reload,"table":_vm.$refs.table},on:{"next-page":_vm.goToNextPage,"previous-page":_vm.goToPreviousPage}},[_c('template',{slot:"actions"},[_vm._t("actions")],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":_vm.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('el-menu-item',{staticClass:"has-link",class:(_vm.onlyExact ? isExactActive : isActive) ? 'is-active' : null,attrs:{"index":route.name}},[_c('a',{attrs:{"href":href},on:{"click":function($event){$event.stopPropagation();_vm.$emit('navigated');
        navigate($event);}}},[_vm._t("default")],2)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
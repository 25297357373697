<template>
  <svg
    :height="h"
    :width="w"
    class="Logo"
    viewBox="0 0 439 128"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>stja@school</title>
    <g :style="{ fill: textColor }">
      <path
        d="M47.8,100.3c0-0.6,0.3-1.2,0.7-1.7c0.4-0.5,1-0.7,1.6-0.7c0.8,0,1.6,0.4,2,1.1c0.8,1.2,1.9,2.1,3.2,2.7
	c1.3,0.7,2.7,1.1,4.1,1.1c0.7,0,1.5-0.1,2.2-0.2c0.8-0.1,1.5-0.4,2.2-0.7c0.8-0.3,1.4-0.9,1.9-1.5c0.5-0.8,0.7-1.7,0.7-2.6
	c0.1-1.1-0.5-2.2-1.5-2.7c-1.8-0.8-3.8-1.4-5.8-1.6L57.1,93L55,92.6c-0.7-0.2-1.4-0.4-2.1-0.7c-0.6-0.3-1.2-0.6-1.8-1
	c-0.6-0.4-1.1-0.9-1.5-1.4c-0.4-0.6-0.7-1.2-0.9-1.9c-0.3-0.8-0.4-1.6-0.4-2.4c0-1.3,0.3-2.7,0.9-3.8c0.6-1.1,1.4-2,2.4-2.7
	c1-0.7,2.2-1.3,3.4-1.6c1.3-0.4,2.6-0.5,4-0.5c3.4,0,6.8,1.1,9.5,3.3c0.7,0.4,1.1,1.2,1.1,2c0,0.6-0.2,1.2-0.7,1.6
	c-0.5,0.4-1.1,0.7-1.7,0.6c-0.5,0-1-0.2-1.4-0.6c-1.9-1.6-4.3-2.4-6.8-2.4c-1.6-0.1-3.2,0.3-4.5,1.2c-0.9,0.7-1.5,1.7-1.5,2.8
	c0,1,0.5,2,1.5,2.4c1.7,0.7,3.6,1.2,5.5,1.4l3.4,0.7c1,0.2,2,0.6,3,1c0.9,0.4,1.8,0.9,2.5,1.6c0.7,0.7,1.2,1.5,1.6,2.3
	c0.4,1.1,0.6,2.2,0.6,3.3c0.1,2.7-1,5.3-3.1,7c-2.4,1.8-5.4,2.8-8.4,2.6c-2.3,0-4.6-0.6-6.6-1.8c-1.8-1-3.3-2.3-4.5-4
	C48,101.3,47.8,100.8,47.8,100.3z"
      />
      <path
        d="M76.1,79.2c0-0.6,0.2-1.2,0.7-1.6c0.4-0.4,1-0.6,1.6-0.6h3.6V66.1c0.1-1.3,1.2-2.3,2.5-2.2c1.2,0.1,2.1,1,2.2,2.2V77h5
	c0.6,0,1.2,0.2,1.6,0.7c0.5,0.4,0.7,1,0.7,1.6c0,1.3-1,2.3-2.3,2.3h-5v16.7c0,0.6,0,1.1,0.2,1.7c0.1,0.5,0.3,1,0.6,1.4
	c0.4,0.5,0.9,0.9,1.6,1.1c0.9,0.3,1.7,0.4,2.6,0.4c1.3,0,2.3,1,2.4,2.3c0,0.6-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7
	c-3.1,0-5.5-0.8-7.2-2.5c-1.7-1.8-2.6-4.2-2.5-6.6V81.6h-3.6C77.1,81.6,76.1,80.5,76.1,79.2z"
      />
      <path
        d="M96.1,119c-0.4-0.4-0.7-1-0.7-1.7c0-0.6,0.2-1.2,0.7-1.7c0.4-0.4,1-0.7,1.6-0.7c0.9,0,1.8-0.1,2.6-0.4
	c0.6-0.2,1.1-0.5,1.5-1c0.3-0.4,0.5-0.9,0.7-1.4c0.1-0.5,0.2-1.1,0.2-1.7V78.7c0-0.6,0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.7-0.7
	c0.6,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6v31.8c0,3-0.8,5.3-2.5,6.8c-1.7,1.6-4,2.3-7.1,2.3C97.1,119.7,96.5,119.4,96.1,119z
	 M102.7,68.6v-2.5c0-1.3,1-2.3,2.2-2.3c0,0,0.1,0,0.1,0c0.6,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.7v2.5c0,1.3-1,2.3-2.3,2.3
	c-0.6,0-1.2-0.2-1.7-0.7C102.9,69.7,102.7,69.1,102.7,68.6L102.7,68.6z"
      />
      <path
        d="M117.5,98.3c-0.1-2.2,0.7-4.3,2.1-6c1.6-1.7,3.7-2.8,6-3.2c3.2-0.6,6.3-1.2,9.4-1.6c0-0.4,0-0.7,0-1s-0.1-0.6-0.1-1.1
	c-0.1-0.4-0.2-0.9-0.3-1.3c-0.2-0.4-0.4-0.8-0.6-1.1c-0.3-0.4-0.6-0.7-1-1c-0.5-0.3-0.9-0.5-1.5-0.7c-0.6-0.2-1.3-0.3-2-0.3
	c-1.2,0-2.4,0.3-3.5,0.7c-1.4,0.5-2.7,1.3-3.8,2.2c-0.4,0.4-0.9,0.5-1.5,0.5c-0.6,0-1.2-0.3-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6
	c0-0.7,0.3-1.3,0.8-1.7c1.5-1.2,3.2-2.2,4.9-2.9c1.7-0.7,3.5-1,5.3-1.1c3.3,0,5.8,1,7.5,3c1.8,2.1,2.7,4.9,2.6,7.6v11.6
	c0,0.8,0.2,1.7,0.5,2.4c0.2,0.7,0.9,1.1,1.6,1.1c0.6,0,1.2,0.2,1.7,0.7c0.4,0.4,0.7,1,0.7,1.7c0,0.6-0.2,1.2-0.7,1.6
	c-0.4,0.4-1,0.7-1.7,0.7c-1.2,0-2.3-0.3-3.3-0.9c-1-0.6-1.7-1.4-2.2-2.4c-2.2,2.5-5.3,3.9-8.6,3.8c-1.5,0-3-0.3-4.4-0.9
	c-1.2-0.5-2.3-1.3-3.2-2.2c-0.8-0.9-1.4-1.8-1.8-2.9C117.8,100.4,117.5,99.4,117.5,98.3z M122.1,98.3c0.1,1.1,0.6,2.2,1.4,3
	c1.1,1,2.6,1.6,4.1,1.5c0.9,0,1.8-0.2,2.6-0.6c0.8-0.3,1.5-0.8,2.1-1.4c0.6-0.5,1.1-1.1,1.5-1.8c0.4-0.6,0.7-1.3,0.9-2
	c0.2-0.5,0.3-1.1,0.3-1.6v-3.1l-8.4,1.5C123.6,94.3,122.1,95.8,122.1,98.3z"
      />
      <path
        d="M152.6,99.7V83.1c0-2.8,0.6-5.5,1.7-8.1c1.1-2.4,2.7-4.5,4.7-6.2c2.1-1.7,4.5-3,7-3.8c2.7-0.9,5.6-1.4,8.4-1.4
	c2.9,0,5.8,0.4,8.5,1.3c2.5,0.8,4.9,2,7,3.6c2,1.6,3.6,3.7,4.6,6.1c1.2,2.6,1.7,5.5,1.7,8.4v15.7c0.1,2.2-0.6,4.3-1.9,6
	c-1.2,1.4-3,2.1-4.8,2.1h-1.9c-2.1,0-4.1-1-5.3-2.8c-2.1,2.1-5,3.2-7.9,3.2c-5.9,0.2-10.9-4.5-11.1-10.4c0-0.2,0-0.4,0-0.7v-8.7
	c0-6.1,5-11.1,11.1-11.1c2.3,0,4.6,0.7,6.5,2.1c0.2-1.2,1.2-2.1,2.4-2.1c0.6,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6v20
	c0,0.8,0.2,1.7,0.5,2.4c0.2,0.7,0.9,1.1,1.6,1.1h1.9c0.7,0,1.4-0.4,1.6-1.1c0.3-0.8,0.5-1.6,0.5-2.4V83.1c0-1.7-0.2-3.5-0.8-5.1
	c-0.4-1.4-1.1-2.7-1.9-3.8c-0.8-1-1.8-1.9-2.8-2.6c-1-0.7-2.1-1.3-3.2-1.7c-1.1-0.4-2.2-0.8-3.3-1c-1-0.2-1.9-0.4-2.9-0.5
	c-0.8-0.1-1.5-0.1-2.2-0.1c-5.1,0-9.2,1.4-12.4,4.1c-3.1,2.6-4.9,6.5-4.8,10.6v16.7c0,4.7,1.5,8.4,4.6,11.2
	c3.1,2.7,7.2,4.1,12.5,4.1c1.3,0,2.3,1.1,2.3,2.3c0,1.3-1,2.3-2.3,2.3c-3.8,0.1-7.7-0.7-11.1-2.3c-3.2-1.5-5.9-3.9-7.8-6.9
	C153.5,107.3,152.5,103.5,152.6,99.7z M168,96.3c-0.1,1.7,0.6,3.5,1.8,4.7c1.2,1.2,2.9,1.8,4.6,1.8c1.7,0,3.3-0.6,4.5-1.8
	c1.3-1.2,2.1-2.9,2-4.7v-8.8c0.1-1.8-0.7-3.5-2-4.7c-1.2-1.2-2.8-1.8-4.5-1.8c-1.7-0.1-3.4,0.6-4.6,1.8c-1.2,1.3-1.9,3-1.8,4.7V96.3
	z"
      />
      <path
        d="M204.9,100.3c0-0.6,0.3-1.2,0.7-1.7c0.4-0.5,1-0.7,1.6-0.7c0.8,0,1.6,0.4,2,1.1c0.8,1.2,1.9,2.1,3.2,2.7
	c1.3,0.7,2.7,1.1,4.1,1.1c0.7,0,1.5-0.1,2.2-0.2c0.7-0.1,1.5-0.3,2.2-0.6c0.8-0.3,1.4-0.9,1.9-1.5c0.5-0.8,0.7-1.7,0.7-2.6
	c0.1-1.1-0.5-2.2-1.5-2.7c-1.8-0.8-3.8-1.4-5.8-1.6l-2-0.5l-2.1-0.5c-0.7-0.2-1.4-0.4-2.1-0.7c-0.6-0.3-1.2-0.6-1.8-1
	c-0.6-0.4-1.1-0.9-1.5-1.4c-0.4-0.6-0.7-1.2-0.9-1.9c-0.3-0.8-0.4-1.6-0.4-2.4c0-1.3,0.3-2.6,0.9-3.8c0.6-1.1,1.4-2,2.4-2.7
	c1-0.7,2.1-1.3,3.3-1.6c1.3-0.4,2.6-0.5,4-0.5c3.4,0,6.8,1.1,9.5,3.3c0.7,0.4,1.1,1.2,1.1,2c0,0.6-0.2,1.2-0.7,1.6
	c-0.4,0.4-1,0.7-1.6,0.7c-0.5,0-1-0.2-1.4-0.6c-1.9-1.6-4.3-2.4-6.8-2.4c-1.6-0.1-3.2,0.3-4.5,1.2c-0.9,0.7-1.5,1.7-1.5,2.8
	c0,1,0.6,2,1.5,2.4c1.7,0.7,3.6,1.2,5.5,1.4l3.4,0.7c1,0.2,2,0.6,3,1c0.9,0.4,1.8,0.9,2.5,1.6c0.7,0.7,1.2,1.5,1.6,2.3
	c0.4,1.1,0.6,2.2,0.6,3.3c0.1,2.7-1,5.3-3.1,7c-2.4,1.8-5.4,2.8-8.4,2.6c-2.3,0-4.6-0.6-6.6-1.8c-1.8-1-3.3-2.3-4.5-4
	C205.2,101.3,205,100.8,204.9,100.3z"
      />
      <path
        d="M235.3,96.3v-8.8c-0.2-5.9,4.4-10.8,10.2-11.1c0.3,0,0.5,0,0.8,0c1.4,0,2.7,0.2,4,0.7c1.1,0.4,2.1,1,3,1.7
	c0.8,0.6,1.5,1.4,2.1,2.1c0.5,0.6,0.9,1.4,1.3,2.1c0.2,0.5,0.4,1,0.4,1.5c0,0.6-0.3,1.2-0.7,1.7c-0.4,0.4-1,0.7-1.7,0.7
	c-0.5,0-1-0.2-1.4-0.5c-0.4-0.3-0.7-0.7-0.9-1.2c-0.4-1.2-1.2-2.3-2.3-3c-1.1-0.8-2.4-1.2-3.8-1.2c-1.7-0.1-3.4,0.6-4.6,1.8
	c-1.2,1.3-1.8,3-1.8,4.7v8.8c-0.1,1.7,0.6,3.4,1.8,4.7c2.3,2.1,5.8,2.4,8.4,0.6c1.1-0.8,1.9-2,2.3-3.3c0.2-0.4,0.5-0.8,0.9-1.1
	c0.4-0.3,0.8-0.4,1.3-0.4c0.6,0,1.2,0.3,1.7,0.7c0.4,0.5,0.7,1,0.7,1.7c0,0.5-0.2,1-0.3,1.4c-0.3,0.7-0.7,1.4-1.2,2.1
	c-0.6,0.8-1.2,1.5-2,2.2c-0.9,0.7-1.9,1.3-3,1.7c-1.3,0.5-2.6,0.7-4,0.7c-5.9,0.2-10.9-4.4-11.1-10.3
	C235.4,96.8,235.4,96.6,235.3,96.3L235.3,96.3z"
      />
      <path
        d="M265.8,105.2V66.1c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,0,0,0,0,0v12.2c1.9-1.3,4.1-1.9,6.4-1.8
	c6.1-0.1,11.1,4.8,11.2,10.8c0,0.1,0,0.2,0,0.2v17.6c0,0.6-0.2,1.2-0.7,1.6c-0.9,0.9-2.4,0.9-3.3,0c-0.4-0.4-0.7-1-0.7-1.6V87.6
	c0-1.7-0.6-3.3-1.8-4.6c-1.2-1.3-2.9-2.1-4.7-2c-3.4-0.2-6.2,2.4-6.4,5.8c0,0.2,0,0.5,0,0.7v17.6c0,0.6-0.2,1.2-0.7,1.6
	c-0.4,0.4-1,0.7-1.7,0.7c-0.6,0-1.2-0.2-1.7-0.7C266.1,106.3,265.8,105.7,265.8,105.2z"
      />
      <path
        d="M297,96.3v-8.8c0-6.1,5-11.1,11.1-11.1s11.1,5,11.1,11.1v8.8c0,3-1.1,5.8-3.3,7.8c-4.2,4.4-11.1,4.6-15.5,0.5
	C298.2,102.5,296.9,99.5,297,96.3L297,96.3z M301.7,96.3c-0.1,1.7,0.6,3.4,1.8,4.7c1.2,1.2,2.9,1.8,4.6,1.8c1.7,0,3.3-0.6,4.5-1.8
	c1.3-1.2,2.1-2.9,2-4.7v-8.8c0.1-1.8-0.7-3.5-2-4.7c-1.2-1.2-2.8-1.8-4.5-1.8c-1.7-0.1-3.4,0.6-4.6,1.8c-1.2,1.3-1.9,3-1.8,4.7V96.3
	z"
      />
      <path
        d="M327.1,96.3v-8.8c0-6.1,5-11.1,11.1-11.1s11.1,5,11.1,11.1v8.8c0,3-1.1,5.8-3.3,7.8c-4.2,4.4-11.1,4.6-15.5,0.5
	C328.3,102.5,327,99.5,327.1,96.3L327.1,96.3z M331.8,96.3c-0.1,1.7,0.6,3.5,1.8,4.7c1.2,1.2,2.9,1.8,4.6,1.8c1.7,0,3.3-0.6,4.5-1.8
	c1.3-1.2,2.1-2.9,2-4.7v-8.8c0.1-1.8-0.7-3.5-2-4.7c-1.2-1.2-2.8-1.8-4.5-1.8c-3.4-0.2-6.2,2.4-6.4,5.8c0,0.2,0,0.5,0,0.7V96.3z"
      />
      <path
        d="M358.4,98.3V66.1c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,0,0,0,0,0v32.2c0,0.6,0,1.1,0.2,1.7c0.1,0.5,0.3,1,0.6,1.4
	c0.4,0.5,0.9,0.9,1.5,1.1c0.8,0.3,1.7,0.4,2.6,0.4c1.3,0,2.3,1,2.4,2.3c0,0.6-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.7,0.7
	c-3.1,0-5.5-0.8-7.2-2.4C359.1,103.2,358.2,100.8,358.4,98.3z"
      />
    </g>
    <path
      :style="{ fill: triangleColor }"
      class="Logo__triangle"
      d="M383.7,56.1c4.6-17.1,9.1-34,13.6-50.7l37.1,37.1L383.7,56.1z"
    />
    <slot />
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: {
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    textColor: {
      type: String,
      default: "inherit",
    },
    triangleColor: {
      type: String,
      default: "#000",
    },
  },
  computed: {
    w() {
      const { width, height } = this;
      if (width !== null) {
        return width;
      }
      if (typeof height === "number") {
        return 439 * (height / 128);
      }
      return 439;
    },
    h() {
      const { width, height } = this;
      if (height !== null) {
        return height;
      }
      if (typeof width === "number") {
        return 128 * (width / 439);
      }
      return 128;
    },
  },
};
</script>

<style lang="scss">
.Logo {
  margin: auto;

  g,
  path {
    transition: fill 0.25s linear;
  }
}
</style>

<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
export default {
  name: "LocaleDateTime",
  props: {
    date: {
      type: [String, Date],
      default: "",
    },
  },
  computed: {
    formattedDate() {
      const { date } = this;

      if (!date) {
        return "";
      }
      return date instanceof Date
        ? date.toLocaleString()
        : new Date(date).toLocaleString();
    },
  },
};
</script>

<style lang="module.scss" module></style>
